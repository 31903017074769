<script lang="ts">
  import { fade } from "svelte/transition";
</script>

<div
  class="flex flex-col w-full mb-3 lg:h-56 bg-white border rounded-lg shadow-md lg:flex-row dark:border-gray-700 dark:bg-gray-800"
>
  <div class="relative flex w-full lg:h-full lg:w-96 animate-pulse">
    <div class="flex items-center justify-center w-full h-56 lg:h-full bg-gray-300 rounded dark:bg-gray-700">
      <svg
        class="w-12 h-12 text-gray-200"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 640 512"
        ><path
          d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"
        /></svg
      >
    </div>
    <span class="sr-only">Loading...</span>
  </div>
  <div class="relative p-2 leading-normal w-full">
    <div class="flex items-center justify-between">
      <div>
        <div class="max-w-sm animate-pulse">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-72 mb-4" />
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12" />
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12" />
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12" />
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <hr class="h-px m-2 bg-gray-200 border-0 dark:bg-gray-700" />
    <div class="w-full h-32">
      <div role="status" class="space-y-2.5 animate-pulse">
        <div class="flex items-center w-full space-x-2">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32" />
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24" />
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full" />
        </div>
        <div class="flex items-center w-full space-x-2 max-w-[480px]">
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full" />
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full" />
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24" />
        </div>
        <div class="flex items-center w-full space-x-2 max-w-[400px]">
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full" />
          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80" />
          <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full" />
        </div>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="absolute inset-x-0 bottom-0 p-2">
      <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
      <div class="flex justify-between">
        <div class="flex items-center ml-4">
          <div class="flex">
            <div class="flex -space-x-4 animate-pulse">
              <svg
                class="text-gray-200 w-8 h-8 dark:text-gray-700"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                /></svg
              >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-2 animate-pulse">
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12" />
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12" />
            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-12" />
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>
